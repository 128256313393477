import { FlowBase, identificationTypes } from "./flow-base";
import * as events from '../events';


class OrangeFrance extends FlowBase {
    handleIntegratorIframeIdentity() {
        //since heIframeIdentity has no error handled add a timeout then call identifyOnError
        this.iframeTimeout = window.setTimeout(() => {
            this.checkIdentify()
        }, this.identifyTimeout * 1000);

        this.integrator.customHeIframeIdentifyBaseUrl(this.identifyEndpoint,
            {
                return: window.location.origin + window.location.pathname,
                metadata: this.metadata,
            }
        );
    }

        //Overriden to show custom error messages
    otpValidateError(response) {
        this.uiControl.hideElement(this.uiControl.controls.spinner);
        if (response.type && response.type === "INSUFFICIENT_CREDIT") {
            this.logger.debug(`[Subscribe Error] Flow (${this.name}): insufficent funds`, response);
            this.setFlowStep(this.flowSteps.SUBSCRIPTION_FAILED);
            this.dispatcher.dispatchEvent(events.SUBSCRIPTION_FAILED, {
                reference: response.reference,
                status_code: response.status_code,
                error: this.returnErrorMessageFromResponse(response),
                response_string: response.response_string || null,
            });
            this.uiControl.showErrorMessage('INSUFFICENT_FUNDS', 'Retry');
        } else {
            this.logger.error(`[Subscribe Error] Flow (${this.name}): received error from integrator`, response);
            this.setFlowStep(this.flowSteps.SUBSCRIPTION_FAILED);
            this.dispatcher.dispatchEvent(events.SUBSCRIPTION_FAILED, {
                reference: response.reference,
                status_code: response.status_code,
                error: this.returnErrorMessageFromResponse(response),
                response_string: response.response_string || null,
            });
            this.uiControl.showErrorMessage('INTEGRATOR_ERROR', 'Retry');
        }
    }
}

const OrangeFranceFlowModule = new OrangeFrance('Orange France HE OTP', identificationTypes.IFRAME, {
    heOtp: true,
    OTP: false,
    usernameFlow: true,
    subscribeClick: true,
    inactiveTimeout: false,
    otpChallengeValidateURL: 'lp/otp/subscribe/fr',
})
window.FlowModule = OrangeFranceFlowModule;
